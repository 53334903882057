import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Health of ECDN server’s content delivery.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> ContentDeliveryHealth <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Aggregated health of services affecting content delivery.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD if all services are in GOOD state.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - WARNING if at least one is in WARNING and none of them are in POOR state.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR if at least one is in POOR state.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">aggregated</GQLName>: <GQLLink href="/ecdn-api-enums/Health" mdxType="GQLLink">Health</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Upstream servers health.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD if all upstreams are reachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - WARNING if not all but at least one of them is reachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR if none of them are reachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">upstreams</GQLName>: <GQLLink href="/ecdn-api-enums/Health" mdxType="GQLLink">Health</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Health of caching service running on ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD if it's up and running, certificate is valid.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - WARNING if it's up and running, but certificate will expire soon.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR if it's not running, certificate is invalid or expired.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">caching</GQLName>: <GQLLink href="/ecdn-api-enums/Health" mdxType="GQLLink">Health</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`aggregated: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Health"
      }}>{`Health`}</a>{`!`}</h3>
    <p>{`Aggregated health of services affecting content delivery.`}</p>
    <ul>
      <li parentName="ul">{`GOOD if all services are in GOOD state.`}</li>
      <li parentName="ul">{`WARNING if at least one is in WARNING and none of them are in POOR state.`}</li>
      <li parentName="ul">{`POOR if at least one is in POOR state.`}</li>
    </ul>
    <h3>{`upstreams: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Health"
      }}>{`Health`}</a>{`!`}</h3>
    <p>{`Upstream servers health.`}</p>
    <ul>
      <li parentName="ul">{`GOOD if all upstreams are reachable.`}</li>
      <li parentName="ul">{`WARNING if not all but at least one of them is reachable.`}</li>
      <li parentName="ul">{`POOR if none of them are reachable.`}</li>
    </ul>
    <h3>{`caching: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Health"
      }}>{`Health`}</a>{`!`}</h3>
    <p>{`Health of caching service running on ECDN server.`}</p>
    <ul>
      <li parentName="ul">{`GOOD if it’s up and running, certificate is valid.`}</li>
      <li parentName="ul">{`WARNING if it’s up and running, but certificate will expire soon.`}</li>
      <li parentName="ul">{`POOR if it’s not running, certificate is invalid or expired.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      